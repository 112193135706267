<template>
  <div>移动端头部</div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      navList: [
        { name: "首页", path: "index" },
        { name: "企业介绍", path: "index" },
        { name: "服务团队", path: "index" },
        { name: "业务范围", path: "index" },
        { name: "优秀案例", path: "index" },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
</style>