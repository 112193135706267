<template>
  <div>
      <pageHead></pageHead>
  </div>
</template>

<script>
import pageHead from '../../components/PageHeadM'
export default {
  components: {
      pageHead
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
</style>